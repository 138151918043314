import { useEffect, useState } from 'react'
import './Home.css'
import { Card, CardContent, Typography, CardActions, Button, Container } from '@mui/material'
import axios from 'axios';
import ModalInfo from './Modal'

const Home = () => {

const [number, setNumber] = useState(0);
const [card, setCard] = useState([])
useEffect(()=> {
  fetch("https://painel.poentecompany.com.br/count.php")
  .then(data => data.json())
  .then(result => {
    setNumber(result)
  })
},[])

useEffect(()=> {
  axios.get("https://painel.poentecompany.com.br/ultimosPreenchidos.php")
  .then(response => setCard(response.data))
},[])

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '1px solid #eee',
  boxShadow: 24,
  borderRadius:'15px',
  p: 4,
};
const [openId, setOpenId] = useState(null);
const handleOpen = (id) => {
  setOpenId(id);
}
const handleClose = () => setOpenId(null);

  return (
    <div>
      <Container>
      <p style={{fontSize:"30px",textAlign:'center'}} >Olá, seja bem vindo!</p>

        <Typography variant='h5' sx={{textAlign:"center"}}>Pessoas que preencheram o formulário: <span>{number}</span></Typography>

        <Typography style={{margin:"60px 0", borderBottom:"1px solid #ccc",width:"200px"}} sx={{fontSize:20}} >
          Últimos cadastros
        </Typography>

        <div style={{display:"flex", justifyContent:"space-around"}}>
          {card.map((e)=>(
            <>
              <Card sx={{ maxWidth: 275 }}>
                <CardContent>
                  <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                    {e.id}
                  </Typography>
                  <Typography variant="h5" component="div">
                    {e.nome}
                  </Typography>
                  <Typography sx={{ mb: 1.5 }} color="text.secondary">
                    {e.nome_empresa}
                  </Typography>
                  <Typography variant="body2">
                    Nº de funcionários: {e.num_funcionarios}
                    <br />
                    Faturamento: {e.faturamento}
                  </Typography>
                </CardContent>
                <CardActions>
                  <Button size="small" onClick={ () => handleOpen(e.id)}>Ver mais</Button>
                </CardActions>
              </Card>
              {
                openId === e.id && (
                  <ModalInfo open={true} handleClose={handleClose} style={style} info={e.id} />
                )
              }
              </>
          ))}
        </div>
        
      </Container>
    </div>
  )
}

export default Home
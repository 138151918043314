import React, { useEffect, useState } from 'react'
import './Contatos.css'
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import axios from 'axios'

const columns = [
  { id: 'id', label: 'ID', minWidth: 30 },
  { id: 'nome', label: 'Nome', minWidth: 100 },
  {
    id: 'email',
    label: 'E-mail',
    minWidth: 100,
    align: 'right'
  },
  {
    id: 'numero',
    label: 'Número',
    minWidth: 100,
    align: 'right',
  },
  {
    id: 'nome_empresa',
    label: 'Nome empresa',
    minWidth: 100,
    align: 'right'
  },  {
    id: 'segmento',
    label: 'Segmento',
    minWidth: 100,
    align: 'right'
  },  {
    id: 'num_funcionario',
    label: 'Nº funcionários',
    minWidth: 100,
    align: 'right'
  },  {
    id: 'faturamento',
    label: 'Faturamento',
    minWidth: 100,
    align: 'right'
  },  {
    id: 'investimento',
    label: 'Investimento',
    minWidth: 100,
    align: 'right'
  }, 
];


const Contatos = () => {
  
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  let [rows, setRows] = useState([]);
  useEffect( ()=>{
    axios.get("https://painel.poentecompany.com.br/select.php")
    .then(response => {
      setRows(response.data)
    })
  },[])

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  return (
    <div>
      <p style={{fontSize:"30px",textAlign:'center'}}>Veja quem preencheu o formulário</p>
      <div>
        <Paper sx={{ width: '100%', overflow: 'hidden' }}>
          <TableContainer sx={{ maxHeight: 440 }}>
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  {columns.map((column) => (
                    <TableCell
                      key={column.id}
                      align={column.align}
                      style={{ minWidth: column.minWidth }}
                    >
                      {column.label}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {rows
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row) => {
                    return (
                      <TableRow hover role="checkbox" tabIndex={-1} key={row.id}>
                        {columns.map((column) => {
                          const value = row[column.id];
                          return (
                            <TableCell key={column.id} align={column.align}>
                              {column.format && typeof value === 'number'
                                ? column.format(value)
                                : value}
                            </TableCell>
                          );
                        })}
                      </TableRow>
                    );
                  })}
              </TableBody>
            </Table>
          </TableContainer>
        <TablePagination
          rowsPerPageOptions={[10, 25, 100]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
    </div>
  </div>

)
}
export default Contatos

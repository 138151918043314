import {useEffect, useState} from 'react'
import { Modal , Box,  Typography} from '@mui/material'
import axios from 'axios'
const ModalInfo = ({open, handleClose, style, info}) => {
  const [result, setResult] = useState({})
      useEffect(() => {
      const fetchData = async () => {
        try {
          const response = await axios.get('https://painel.poentecompany.com.br/detalhesSingle.php', {
            params: {
              id: info
            },
            headers: {
              'Content-Type': 'application/json',
              // Outros headers aqui, se necessário
            },
            // Outras opções aqui, se necessário
          });

          setResult(response.data);
        } catch (error) {
          console.error('Erro na requisição:', error);
        }
      };

      fetchData();
    }, []);
  return (
    <div>
                <Modal
                  open={open}
                  onClose={handleClose}
                  aria-labelledby="modal-modal-title"
                  aria-describedby="modal-modal-description"
                >
                  <Box sx={style}>
                    <Typography id="modal-modal-title" variant="h6" component="h2">
                      <h2>Detalhes do cliente {result.nome}</h2>
                    </Typography>
                    <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                      <h3>Email: <a href={`mailto:${result.email}`}>{result.email}</a></h3>
                      <h3>Segmento: {result.segmento}</h3>
                      <h3>Está disposto a inestir: {result.investimento}</h3>
                      <h3>N° de funcionários: {result.num_funcionarios}</h3>
                      <h3>Telefone: <a href={`tel:${result.numero}`}>{result.numero}</a></h3>
                      <h3>Nome da empresa: {result.nome_empresa}</h3>
                      <h3>Faturamento da empresa: {result.email}</h3>
                    </Typography>
                  </Box>
                </Modal>
    </div>
  )
}

export default ModalInfo
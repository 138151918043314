import './App.css';
import {BrowserRouter, Routes, Route} from 'react-router-dom'
import Home from './components/Home';
import Contatos from './components/Contatos';
import Navbar from './components/Navbar';
import { Container } from '@mui/material';
function App() {
  return (
    <div className="App">
      <BrowserRouter>
      <Navbar/>
        <Container>
          <Routes>
            <Route path='/' element={<Home/>} />
            <Route path='/cadastros' element={<Contatos/>}/>
          </Routes>
          </Container>
      </BrowserRouter>
    </div>
  );
}

export default App;

import React from 'react'
import './Navbar.css'
import { Link } from 'react-router-dom'
import { Card } from '@mui/material';
import Logo from '../imgs/logo.png'
const Navbar = () => {
  return (
    <>
        <nav className='navbar'>
            <div className="logo">
                <Link to="/"><img src={Logo} alt="Logo Poente Company" /></Link>
            </div>
            <div className="menu-links">
                <Link to="/">
                    <Card sx={{ display:"flex",justifyContent:"center", borderColor: "#ccc", padding:"10px" }}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-house" viewBox="0 0 16 16">
                            <path d="M8.707 1.5a1 1 0 0 0-1.414 0L.646 8.146a.5.5 0 0 0 .708.708L2 8.207V13.5A1.5 1.5 0 0 0 3.5 15h9a1.5 1.5 0 0 0 1.5-1.5V8.207l.646.647a.5.5 0 0 0 .708-.708L13 5.793V2.5a.5.5 0 0 0-.5-.5h-1a.5.5 0 0 0-.5.5v1.293L8.707 1.5ZM13 7.207V13.5a.5.5 0 0 1-.5.5h-9a.5.5 0 0 1-.5-.5V7.207l5-5 5 5Z"/>
                        </svg>
                    </Card>
                </Link>
                <Link to="/cadastros">
                    <Card sx={{ display:"flex",justifyContent:"center", borderColor: "#ccc", padding:"10px"}}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-bar-chart-fill" viewBox="0 0 16 16">
                        <path d="M1 11a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v3a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1v-3zm5-4a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v7a1 1 0 0 1-1 1H7a1 1 0 0 1-1-1V7zm5-5a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1h-2a1 1 0 0 1-1-1V2z"/>
                    </svg>
                    </Card>
                </Link>
            </div>
        </nav>
    </>
  )
}

export default Navbar